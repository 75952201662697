import React from 'react';
import styled from '@emotion/styled';

import { LinkInternal, LinkExternal } from "@components/LinkNav";
import MDXRenderer from "@components/MDX";
import Icons from "@icons";

import mediaqueries from '@styles/media';
import { IArticle, IAuthor } from '@types';

interface ArticleHeroProps {
  article: IArticle;
  authors: IAuthor[];
}

const ArticleHero: React.FC<ArticleHeroProps> = ({ article, authors }) => {

  return (
    <Hero>
      <Header>
        <HeroHeading>{article.title}</HeroHeading>
        <Excerpt>{"@" + article.credit + " said: " + article.excerpt}</Excerpt>
        {/* <ArticleBody>
          <MDXRenderer content={article.body}>
          </MDXRenderer>
        </ArticleBody> */}
        <Credit>
          Credit:
          <LinkExternal href={article.siteLink} target="_blank" rel="noopener">{"@" + article.credit}
            <Icons.ArrowExternal />
          </LinkExternal>
        </Credit>
        <ArticleCategory>
          Category:
          {authors.map((author, index) => (
            <span>
              <LinkInternal to={author.slug} key={index}>{author.name}</LinkInternal>
              {index + 1 < authors.length ? (
                <span> / </span>
              ) : ('')}
            </span>
          ))}
        </ArticleCategory>
      </Header>
    </Hero>
  );
};

export default ArticleHero;

const Hero = styled.div`
  
`;

const Header = styled.header`
  position: relative;
  z-index: 10;

  ${mediaqueries.tablet`
    padding: 0 24px;
  `}
`;

const HeroHeading = styled.h1`
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -2px;
  font-weight: ${p => p.theme.fontsWeight.bold};
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.title};
  margin-bottom: 40px;

  ${mediaqueries.tablet`
    margin-bottom: 16px;
  `}
`;

const Excerpt = styled.h3`
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  font-family: ${p => p.theme.fonts.body};
  color: ${p => p.theme.colors.secondary};
  margin-bottom: 32px;
  font-weight: normal;
`;

const ArticleCategory = styled.p`
  letter-spacing: 0;
  font-size: 14px;
  line-height: 24px;
  font-family: ${p => p.theme.fonts.body};
  font-weight: ${p => p.theme.fontsWeight.regular};
  color: ${p => p.theme.colors.secondary};
  margin-bottom: 16px;
  text-transform: uppercase;

  a {
    box-shadow: inset 0 -2px 0 ${p => p.theme.colors.card};
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const Credit = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.secondary};
  text-transform: uppercase;
  margin-bottom: 8px;

  a {
    box-shadow: inset 0 -2px 0 ${p => p.theme.colors.card};
    margin-left: 8px;
  }

  svg {
    margin-left: 8px;
    path {
      fill: ${p => p.theme.colors.secondary};
    }

  &:hover svg {
      path {
        fill: ${p => p.theme.colors.accent};
      }
    }
  }
`;

const ArticleBody = styled.article`
  position: relative;
  transition: background 0.2s linear;
  
  ${mediaqueries.tablet`
    padding: 0;
  `}
`;