import React from "react";
import styled from "@emotion/styled";
import ListItem from '../articles/List.Item';

import mediaqueries from "@styles/media";
import { IArticle } from "@types";

interface ArticlesNextProps {
  articles: IArticle[]
}

/**
 * Sits at the bottom of our Article page. Shows the next 2 on desktop and the
 * next 1 on mobile!
 *
 *  [..............], [.........]
 *  [.....LONG.....], [..SHORT..]
 *  [..............], [.........]
 *
 * This does NOT use Articles.List because there's a special case of only have 1 article
 * as the next one suggested article, which requires special styling we didn't want to
 * mix into the generic list component.
 */
const ArticlesNext: React.FC<ArticlesNextProps> = ({ articles }) => {
  if (!articles) return null;
  const numberOfArticles = articles.length;
  return (
    <Container>
      <HeadingWrap>
        <Heading>Next</Heading>
      </HeadingWrap>
      <Grid numberOfArticles={numberOfArticles}>
        <ListItem article={articles[0]} squareLayout={true} />
        {/* <ListItem article={articles[1]} squareLayout={true} /> */}
      </Grid>
    </Container>
  );
};

export default ArticlesNext;

const Container = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  // background-color: ${p => p.theme.colors.primary};
  // box-shadow: ${p => p.theme.colors.softShadow};
  transform: rotate(-8deg) ;
  scale: 0.85;

  ${mediaqueries.tablet`
    // display: none;
  `}
`;

const Grid = styled.div<{ numberOfArticles: number }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1px;
  max-width: ${p => (p.numberOfArticles === 1 ? "680px" : "100%")};

`;

const HeadingWrap = styled.div`
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 2;
  text-align: center;
  background-color: ${p => p.theme.colors.accent};
  padding: 16px 32px;
`;

const Heading = styled.h1`
  width: 100%;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  font-weight: ${p => p.theme.fontsWeight.bold};
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.title};
`;