import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Image, { ImagePlaceholder } from '@components/Image';

import { IArticle } from '@types';

interface ArticlesListItemProps {
  article: IArticle;
  squareLayout?: boolean;
}

const ListItem: React.FC<ArticlesListItemProps> = ({ article, squareLayout }) => {
  if (!article) return null;

  const imageSource = article.hero.regular;
  const hasHeroImage =
    imageSource &&
    Object.keys(imageSource).length !== 0 &&
    imageSource.constructor === Object;

  return (
    <ArticleLink to={article.slug} data-a11y="false">
      <ImageContainer squareLayout={squareLayout}>
        {hasHeroImage ? 
        <Image src={imageSource}  alt={article.title} imgStyle={{ objectFit: 'cover', objectPosition: 'center center' }} />
        : 
        <ImagePlaceholder />}
      </ImageContainer>
    </ArticleLink>
  );
};

export default ListItem;

const ImageContainer = styled.div<{squareLayout: boolean}>`
  transition: all 0.25s var(--ease-out-quad);
  position: relative;
  padding-top: ${p => (p.squareLayout ? "100%" : "none")};
  height: ${p => (p.squareLayout ? "none" : "100%")};
  overflow: hidden;

  & > div {
    top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute !important;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: ${p => p.theme.colors.background};
    opacity: 0;
    transition: opacity 0.25s var(--ease-out-quad);
  }

`;

const ArticleLink = styled(Link)`
  position: relative;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  overflow: hidden;

  &:hover ${ImageContainer}, &:focus ${ImageContainer} {
    &::after {
      opacity: 0.3;
    }
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -1.5%;
    top: -2%;
    width: 103%;
    height: 104%;
    border: 3px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
`;
