import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import throttle from "lodash/throttle";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "@components/Layout";
import Section from "@components/Section";
import Subscription from "@components/Subscription";

import mediaqueries from "@styles/media";
import { debounce } from "@utils";

import ArticleHero from "../sections/article/Article.Hero";
import ArticleGallery from "../sections/article/Article.Gallery";
import ArticleSEO from "../sections/article/Article.SEO";


import { Template } from "@types";

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
          }
        }
      }
    }
  }
`;

const Article: Template = ({ pageContext, location }) => {
  const contentSectionRef = useRef<HTMLElement>(null);

  const [hasCalculated, setHasCalculated] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const results = useStaticQuery(siteQuery);
  const name = results.allSite.edges[0].node.siteMetadata.name;

  const { article, authors, mailchimp, next } = pageContext;

  useEffect(() => {
    const calculateBodySize = throttle(() => {
      const contentSection = contentSectionRef.current;

      if (!contentSection) return;

      /**
       * If we haven't checked the content's height before,
       * we want to add listeners to the content area's
       * imagery to recheck when it's loaded
       */
      if (!hasCalculated) {
        const debouncedCalculation = debounce(calculateBodySize);
        const $imgs = contentSection.querySelectorAll("img");

        $imgs.forEach($img => {
          // If the image hasn't finished loading then add a listener
          if (!$img.complete) $img.onload = debouncedCalculation;
        });

        // Prevent rerun of the listener attachment
        setHasCalculated(true);
      }

      // Set the height and offset of the content area
      setContentHeight(contentSection.getBoundingClientRect().height);
    }, 20);

    calculateBodySize();
    window.addEventListener("resize", calculateBodySize);

    return () => window.removeEventListener("resize", calculateBodySize);
  }, []);

  return (
    <Layout>
      <ArticleSEO article={article} authors={authors} location={location} />
      <div id="top">
        <LayoutContainer>
          <ArticleContainer>
            <ArticleGalleryContainer>
              <Anchor>
                <AnchorLink to={`${location.pathname}#top`}></AnchorLink>
              </Anchor>
              <ArticleGallery article={article} next={next}/>
            </ArticleGalleryContainer>
            
            <Section narrow>
              <SideBar>
                <ArticleHero article={article} authors={authors} />
                <SubscriptionWrap>
                  <Subscription />
                </SubscriptionWrap>
              </SideBar>
            </Section>
            
          </ArticleContainer>
            
        </LayoutContainer>
      </div>

    </Layout>
  );
};

export default Article;

const LayoutContainer = styled.div`
  position: relative;

  ${mediaqueries.desktop_medium`
    grid-template-columns: 1fr;
  `}
`;

const ArticleContainer = styled.div`
  position: relative;

  ${mediaqueries.desktop`
    grid-template-columns: 1fr;
  `}

`;

const SubscriptionWrap = styled.div`
  position: relative;
  
  ${mediaqueries.tablet`
    padding: 0 24px;
  `}
`;

const SideBar = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 480px;
  column-gap: 72px;
  row-gap: 72px;
  
  ${mediaqueries.desktop`
    grid-template-columns: 1fr;
  `}
`;

const ArticleGalleryContainer = styled.div`
  position: relative;
`;

const Anchor = styled.div`
  width: 100%;
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 10000;
  background:  ${p => p.theme.colors.accent};
  opacity: 0;
  margin-top: -48px;
  
  ${mediaqueries.desktop`
    margin-top: -16px;
  `}

  a {
    display: block;
    height: 100%;
  }
`;
