import React, { useState, useEffect } from "react";
import styled from '@emotion/styled';

import Image, { ImagePlaceholder } from '@components/Image';
import mediaqueries from '@styles/media';
import { IArticle, IAuthor } from '@types';
import {
  getWindowDimensions,
  getBreakpointFromTheme,
} from "@utils";

import ArticlesNext from "./Article.Next";

interface ArticleHeroProps {
  article: IArticle;
  next: IArticle;
}

const ArticleGallery: React.FC<ArticleHeroProps> = ({ article, next }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  
  useEffect(() => {
    const { width } = getWindowDimensions();
    const tablet = getBreakpointFromTheme("tablet");

    setIsMobile(
      width <= tablet,
    );
  }, []);
  
  const hasHeroImage =
    article.hero &&
    Object.keys(article.hero.full).length !== 0 &&
    article.hero.full.constructor === Object;
  
  return (
    <GalleryContainer>

      <Wrapper>
        <ImagesContainer>
          <HeroImage id="ArticleImage__Hero">
            {hasHeroImage ? (
              <Image src={article.hero.full} alt={article.title}/>
            ) : (
              <ImagePlaceholder />
            )}
          </HeroImage>

          {article.imageGallery && article.imageGallery.map((img, index) => {
            return (
              <ImageWrap
                key={index}
                style={{ 
                  left: isMobile ? `${index*2}px` : `${4 + index*4}px`,
                  top: isMobile ? `${12 + index*4}px` : "0",
                }}
              >
                <Image src={img.full} alt={article.title}/>
              </ImageWrap>
            );
          })}
          {next.length > 0 && (
            <HeroImage>
              <ArticlesNext articles={next} />
            </HeroImage>
          )}
        </ImagesContainer>
      </Wrapper>


    </GalleryContainer>
  );
};

export default ArticleGallery;

const GalleryContainer = styled.div`
  position: relative;
  overflow-x: scroll;
  margin-bottom: 40px;
  margin-left: 64px;
  cursor: auto; 

  ${mediaqueries.tablet`
    overflow-x: visible;
    margin-left: 0;

  `}

  &::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    cursor: pointer;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.2);
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background: rgba(255,255,255,.5);
    }
  }
`;
  
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 16px;

  ${mediaqueries.tablet`
    display: block;
    padding-left: 0;
  `}
`;
  
const ImagesContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(11, 500px);
  column-gap: 1px;

  ${mediaqueries.desktop`
    grid-template-columns: repeat(11, 450px);
  `}

  ${mediaqueries.tablet`
    grid-template-columns:  1fr;
    // grid-template-columns: repeat(11, 92vw);
  `}
`;


const HeroImage = styled.div`
  width: 100%;
  position: sticky;
  left: 0;

  ${mediaqueries.tablet`
    margin-bottom: 4px;
    left: 0;
    top: 8px;
  `}
`;

const ImageWrap = styled.div`
  box-shadow: ${p => p.theme.colors.softShadow};
  position: sticky;

  ${mediaqueries.tablet`
    margin-bottom: 4px;
  `}
`;
